import React from "react";
import { Link } from "gatsby";

export default class Construction extends React.Component {
  render() {
    let title = "Under Development";
    if (this.props.title) {
      title = this.props.title;
    }

    let backButtonTitle = "Back";
    if (this.props.backButtonTitle) {
      backButtonTitle = this.props.backButtonTitle;
    }

    let backPage = "/";
    if (this.props.backPage) {
      backPage = this.props.backPage;
    }

    return (
      <div className="navbar-padding highlight-clean mt-5">
        <div className="container">
          <div className="intro">
            <div className="text-center text-white text-uppercase font-weight-light h2">
              {title}
            </div>
            <p className="text-center text-white font-weight-light">
              We know you want to see this page, but it's not finished yet, so
              you'll have to wait a bit longer.
            </p>
          </div>

          <div className="text-center">
            <Link
              to={backPage}
              className="btn btn-primary-tss text-uppercase mb-3"
            >
              {backButtonTitle}
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
