import React from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Container,
  Collapse
} from "reactstrap";

// CSS imports
import "../../assets/sass/navbar.scss";

// Image imports
import Logo from "../../assets/img/Eagle_White_Fill.svg";
import BrandName from "../../assets/img/TalonStrike_SoftwareSolutions_White.png";

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
      <Navbar
        className="navbar-tss bg-dark fixed-top"
        color="light"
        light={true}
        expand="md"
        fixed="true"
      >
        <Container>
          <NavbarBrand href="/">
            <img
              src={Logo}
              className="brand-logo align-middle"
              alt="Brand Logo"
            />
            <span className="brand-name align-middle">
              <img src={BrandName} alt="Logo" />
            </span>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/#solutions">SOLUTIONS</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/#services">SERVICES</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/blog">BLOG</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/about">ABOUT</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/contact">CONTACT</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}
