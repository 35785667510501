import React from "react";
//import 'bootstrap/dist/css/bootstrap.min.css';

// Component imports
import Header from "./Header";
import MainFooter from "../index/MainFooter";

// Image imports

// CSS imports
import "../../assets/sass/common.scss";

export default ({ footerPositioning = "bottom", children }) => {
  return (
    <div>
      <Header />
      {children}
      <MainFooter positioning={footerPositioning} />
    </div>
  );
};
