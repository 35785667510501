import React from "react";

// CSS imports
import "../../assets/sass/footer.scss";

export default class MainFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };
// icon ion-social-twitter fa-facebook
  render() {
    let footerClass = "footer-basic footer py-1";  
    if (this.props.positioning === "fixed") {
     footerClass =  "footer-basic footer-fixed footer py-1";    
    } 
    return (
      <div id="main-footer" className={footerClass}>
        <footer>
          <div className="social pb-1 pt-2">
            <a href="https://www.facebook.com/TalonStrikeSoftware">
              <i className="fab fa-facebook-f text-white" />
            </a>
            <a href="https://twitter.com/TalonStrikeSW">
              <i className="fab fa-twitter text-white" />
            </a>
          </div>
          <ul className="list-inline">
            <li className="list-inline-item">
              <a href="/" className="footer-link text-uppercase">
                Home
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/services" className="footer-link text-uppercase">
                Services
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/about" className="footer-link text-uppercase">
                About
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/terms" className="footer-link text-uppercase">
                Terms
              </a>
            </li>
            <li className="list-inline-item">
              <a href="/privacy" className="footer-link text-uppercase">
                Privacy
              </a>
            </li>
          </ul>
          <p className="copyright py-1 m-0">
            TalonStrike Software Solutions © {new Date().getFullYear().toString()}
          </p>
        </footer>
      </div>
    );
  }
}
